import styled from 'styled-components'

const AddToCartBtn = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  background: #DF8136;
  border: none;
  &:hover{
    // display: inline-block;
    // transform: translate(0,0);
    transform: scale(1.01);
    cursor: pointer;
  },

  :active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    animation: moveInBottom .8s ease-out !important;
    animation-fill-mode: backwards !important;

  }

  @keyframes moveInBottom {
    0% {
        // opacity: 0;
        transform: translateY(20px);
    }

    100% {
        // opacity: 1;
        transform: translateY(0px);
    }
  }
`

export default AddToCartBtn
